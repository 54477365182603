/* Footer */

.footer_container {
    background: rgba(7, 8, 13, 1);
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer_links {
    width: 100%;
    height: 185px;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer_links_wrapper{
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
}

.footer_link--items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: auto;
    box-sizing: border-box;
}

.footer_link--items h4 {
    font-size: 1.2em;
    margin-bottom: 16px;
    color: white;
}

.footer_link--items a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    margin-bottom: 0.5rem;
    transition: 0.3s ease-out;
}

.footer_link--items button {
    border: 0px;
    font-family: Raleway;
    font-size: 1em;
    width: 100px;
}

.footer_link--items a:hover {
    color: #7a9cbe;
}

@media (max-width: 530px){

}