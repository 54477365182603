.skills_courses_wrapper {
  background: rgba(7, 8, 13, 1);
  position: relative;

  .skills_courses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .skills-languages {
      display: flex;
      gap: 10%;
      width: 80%;
      z-index: 1;
    }

    .separator {
      width: 2px;
      background-color: rgba(205, 217, 255, 0.701);
    }

    .languages {
      width: 100%;

      ul {
        list-style: none;
      }
    }

    .skills,
    .languages-container {
      width: 100%;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 2vw;

      z-index: 1;

      .skill_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 60px;

        padding: 30px 20px 10px 20px;

        background: rgba(41, 40, 40, 0.5);
        border-bottom-left-radius: 10px;
        border-top-right-radius: 18px;

        height: 240px;

        position: relative;

        text-align: left;

        h3 {
          width: 100%;
        }
        p {
          font-size: 0.9em;
        }

        .logo {
          position: absolute;
          top: -60px;
          left: 30px;
        }
      }

      .skill_wrapper:nth-of-type(4),
      .skill_wrapper:nth-of-type(6) {
        .logo {
          top: -35px;
        }
      }
    }
  }

  .skills_deco1 {
    position: absolute;
    width: auto;
    left: -30px;
    top: 300px;
    z-index: 0;

    @media (max-width: 800px) {
      display: none;
    }
  }
  .skills_deco2 {
    position: absolute;
    width: auto;
    right: 0;
    top: 100px;
    z-index: 0;
  }
}

@media screen and (max-width: 1200px) {
    .skills_courses_wrapper{
        .skills_courses{
            .skills {
                .skill_wrapper {
                    min-height: unset;
                    height: 120px;
                }
              }
          }
          .skill-description {
            display: none;
          }
    }
  
}
