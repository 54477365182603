/* Boxes */
.projects {
    margin-top: 40px;
    height: auto;
    /*background-image: url(imgs/banner6.png);*/
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
        .elements_wrapper {
            width: 80%;
            padding: 30px 60px;

            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-template-rows: repeat(2, 1fr);
            grid-gap: 3vw;
            
            text-align: center;

            @media (max-width:820px){
                width: 100%;
            }

            .card_wrapper{
                width: 280px;

                @media (max-width:830px){
                    width: auto;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    

                }

            .card {
                // border: 3px solid black;
                position: relative;
            }

            .card, .card2{
                color: #ffffff;
                background-size: cover;
                padding: 12rem 0 0;
                width: 280px;
                height: 280px;
                border-radius: 3px;
                overflow: hidden;
                cursor:pointer;
                transition: transform 500ms ease;
                box-shadow: 3px 5px 8px rgb(34, 34, 34); 

                @media(max-width:600px){
                    width: 190px;
                    height: 190px;
                    margin: 0;
                }
            }

            .pop_up{
                position: absolute;
                top: 0;
                color: black;
                border: 1px solid white;
                border-radius: 15px;
                background-color: rgba(0, 0, 0, 0.4);
            }

            .card-below{
                width: 100%;
                text-align: left;
                @media (max-width:820px){
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
        
}

/* Cards */
.hwrapper {
    text-align: center;
    padding-top: 10px;
}



.card_wrapper:nth-of-type(1) {
    .card{
        background-image: url(./imgs/projects/1.png);
    }
}

.card_wrapper:nth-of-type(2) {
    .card{
        background-image: url(./imgs/projects/2.png);
    }
}

.card_wrapper:nth-of-type(3) {
    .card{
        background-image: url(./imgs/projects/3.webp);
    }
}

.card_wrapper:nth-of-type(4) {
    .card{
        background-image: url(./imgs/projects/4.png);
    }
}

.card_wrapper:nth-of-type(5) {
    .card{
        background-image: url(./imgs/projects/5.png);
    }
}

.card_wrapper:nth-of-type(6) {
    .card{
            background-image: url(./imgs/projects/6.jpg);

    }
}

.card_wrapper:nth-of-type(7) {
    .card{
            background-image: url(./imgs/projects/7.jpg);   
    }
}

.card_wrapper:nth-of-type(8) {
    .card{
            background-image: url(./imgs/projects/8.jpg);   
    }
}

.card_wrapper:nth-of-type(9) {
    .card{
            background-image: url(./imgs/projects/9.jpg);   
    }
}


/* H O V E R    O N L Y    F U L L    S C R E E N */

@media (min-width:981px){
    .card:hover {
        transform: scale(1.05);
        cursor: auto;
    }

    .card:hover .card-content{
        transform: translateY(5%);
    }
    
    .card:hover .card-content > * {
        opacity: 1;
    }

    .card:hover .card-title::after {
        transform: scaleX(1);
    }

}

/* To work only when mobile to take you to projects */
.mobile_proj_links{
    display: none;
    position: relative;
    top: -180px;
    width: 30vw;
    height: 30vh;
}


.card-content {
    margin-top: 20px;
    --padding: 1rem;
    padding: var(--padding);
    background: linear-gradient(
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 0.5) 10%,
        hsl(0 0% 0% / 1)
    );
    transform: translateY(65%);
    transition: transform 500ms ease;
}

.card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 500ms linear;
}

.card-title {
    position: relative;
    width: max-content;
    top: -12px;
    font-size: 1.2em;
}

.card-title::after {
    content: "";
    position: absolute;
    height: 4px;
    left: calc(var(--padding) * -1);
    bottom: -2px;
    background: white;
    width: calc(100% + var(--padding));
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 500ms ease;
}

.card-body {
    color: rgb(255 255 255 / 0.8);
}

#hidden_card, #hidden_card2 {
    // visibility: hidden;
}

.button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: rgb(121, 226, 121);
    background-color:#515794;
    padding: 0.5em 1.25em;
    border-radius: 0.25rem;
}

.button:hover,
.button:focus {
    background-color: #2cb5e8;
}

.nextsection {
    height: 300px;
}

.other_wrapper{
    display: flex;
    justify-content: center;
    background-color: rgba(26, 82, 141, 0.4);
}
.other_container{
    width: 60%;
    text-align: center;

    a{
        color: #75dcff;
    }
}