/* Banner */

.banner {
    padding-top: 60px;
    font-size: 1.1rem;
    text-shadow: rgb(0, 0, 0) 1px 0 1px;
    height: 750px;
    
    background: radial-gradient(circle, rgb(12, 7, 56) 0%, rgb(8, 24, 56) 18%, rgba(12,17,27,1) 40%);

    text-align: center;
    justify-content: center;

    position: relative;

    .banner_text{
        z-index: 1;
    }

    .bimg{
        animation: breathSmall 3s ease-in-out infinite alternate;
    }


    @keyframes breathSmall {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.1);
        }
    }
    @keyframes breathBig {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.5);
        }
    }

    .sphere{
        position: absolute;
        width: 20%;
        height: 20%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: #33648a6d;
        filter: blur(182px);
        transform: rotate(-35.69deg);
        z-index: 1;

        animation: breathBig 4s ease-in-out infinite alternate;
    }

    .b-logo-1{
        position: absolute;
        top: 23%;
        left: 20%;

        @media (max-width: 1126px){
            display: none;
        }
    }
    .b-logo-2{
        position: absolute;
        top: 16%;
        right: 20%;
       
        @media (max-width: 1126px){
            display: none;
        }
    }
    .b-logo-3{
        position: absolute;
        top: 64%;
        left: 28%;
        
        @media (max-width: 1126px){
            display: none;
        }
    }
    .b-logo-4{
        position: absolute;
        top: 58%;
        right: 30%;

        @media (max-width: 1126px){
            display: none;
        }
    }
}

.banner h1 {
    margin-top: 120px;
    opacity: 0;
    transform: translateX(-3rem);
    animation: fadeIn 2s forwards;
    font-size: 2.5em;
  
    .multicolor{
         background: linear-gradient(to right, #FF7A00 0.15%, #BD00FF 74.86%);
        /* you can change the colors based on your preference */
        -webkit-background-clip: text;
        color: transparent;
    }
}

.banner h3{
    margin-top: 20px;
    font: moon;
}

.banner h3, .banner p {
    opacity: 0;
    transform: translateY(3rem);
    animation: fadeIn 2s +0.5s forwards;
}

@keyframes nameFade {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.banner_quote{
    text-align: center;
    margin-top: 200px;
}

.banner_quote {
    opacity: 0;
    transform: translateX(-3rem);
    animation: nameFade 2s +1s forwards;
}

#span_animation:before{
    content: '';
    animation: animate infinite 16s;
    transition: all ease-in-out;
}

@keyframes animate{
    0%{
        content: 'Welcome';
    }
    20%{
        content: 'Wilkommen';
    }
    40%{
        content: 'Bienvenue';
    }
    60%{
        content: 'Bienvenido';
    }
    80%{
        content: 'Benvenuto';
    }
    100%{
        content: '환영하다';
    }

}