.roman-numerals{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    
        background-image: url(../styles/imgs/roman2.jpeg);
    
        position: relative;
        padding: 50px 0;
        color: black;

        p{
            margin: 0;
        }

        .roman-content{
            background-color: rgba($color: #7dadac, $alpha: 0.7);
            padding: 30px;
            border-radius: 10px;
            text-align: center;
        }
    
        @media (max-width:900px){
            flex-direction:column-reverse;
            
        }
}

.roman-wrapper{
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: 20px;

    border-radius: 10px;

    width: 350px;
    height: 200px;

    background-color: rgba($color: #70f1f5, $alpha: 0.5);
}

.roman-close-button{
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 5px;
    padding: 5px;
    width: auto;
    height: 30px;
    // background-color: white;
    color: rgb(255, 87, 87);
    font-weight: 600;
    font-size: 0.8em;
}