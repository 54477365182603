@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,600;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');
// Poppins
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Partial
@import 'Header.scss';
@import 'Banner.scss';
@import 'About.scss';
@import 'Skills.scss';
@import 'Courses.scss';
@import 'Projects.scss';
@import 'LastSection.scss';
@import 'Social.scss';
@import 'Music.scss';
@import 'RomanNumerals.scss';
@import '../components/Language/Language.scss';
@import '../components/Footer/Footer.scss';


h1, h3, button, h2, h4, p, a, footer {
    font-family: 'Poppins', 'Dosis', 'Raleway';
}

ul{
    padding: 0;
}

/*Create a variable to easy apply anywhere you want */
:root{
    --mainColor:#7a9cbe;
    --secondColor:#ffffff;

    --borderColor:#c1c1c1;
    
    --mainText:black;
    --secondText:#4b5156;
}

*,
*::before,
*::after{
    box-sizing: border-box;
}

/*.s1.scrolled {
    background: linear-gradient(
        hsl(197, 40%, 70%),
        hsl(197, 89%, 82%, 0.8) 40%,
        hsl(197, 40%, 100%, 0)
    );;
}*/

.subtitle{
    font-size: 2em;
}

a{
    text-decoration: none;
}

body {
    margin: 0px;
    scroll-behavior: smooth;
    color: white;

    .home_wrapper{
        position: relative; 
    }
}

/* | */

/* Buttons */

.buttons a{
    padding: 0 3px;
}

.buttons {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    list-style: none;
}

button {
    width: 100px;
    padding: 5px;
    text-align: center;
    border: none;
    border-radius: 12px;

    font-size: 1.1rem;

    color: white;

    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all ease 200ms;
    background: transparent;
}

.buttons button:hover{
    box-shadow: 3px 2px 2px rgba(26, 28, 64, 0.482);
}

.buttons .button a{
    color: white;
    font-size: 2em;
}

#about a{
    padding: 10px;
}

button:hover span{
    width: 100%;
    transition: 0.8s;
}

/* Bullets */
.bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 7vw;
    padding: 40px 7vw;
    /* background-color: rgba(133, 202, 238, 0.4); */
    background: linear-gradient(35deg, #CCFFFF, #FFCCCC);
    border-radius: 6px;
}

.bullets_wrapper {
    /*display: flex;*/
    margin: 0;
    border-bottom: 1px solid black;
    flex-direction: column;
    padding: 0 0 20px 0;
    font-family: Raleway;
    font-weight: bold;
}

.bullets_wrapper2{
    padding: 0 0 20px 0;
    font-family: Raleway;
    padding-left: 10vw;
}

.skills_container{
    display: flex;
    justify-content: center;
}

.skills {
    display: flex;
    margin: 50px 0 75px 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    text-align: center;
    list-style: none;
    margin-left: 0;
    font-family: moon;
    width: 90vw;
}

.logos {
    display: flex;
    flex-direction: column;
    width:7%;
    height: 140px;
    margin: 0 22px;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: transform 400ms ease;
    
    img{
        margin-bottom: 20%;
        width: 100%;
        height: auto;
    }
    p{
        margin: 0;
        display: none;
    }
}

.logos:hover {
    transform: scale(1.1);
    cursor:pointer;
}

.over{
    z-index: 1;
}

#buttonCopy {
    color: white;
    padding: 0;
    margin-bottom: 0.5rem;
    width: auto;
    left: -2px;
}

#first_footer a {
    margin-top: 8px;

}

#email {
    display: none;
}

/* For the copy paste function */
#email_invisible {
    visibility:hidden;
}

#hamb_menu{
    display: none;
}

#hamb_menu .bar {
    display: block;
    cursor: pointer;
  }

  /* * * * * * * 9 8 0   P X    M E D I A    Q U E R Y   1 * * */

@media (max-width: 980px){

    header{
        justify-content: space-between;
    }

    header a{
        align-self: flex-start;
    }

    .buttons{
        display: none;
        
    }

    .buttons.active{
        display: flex;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: rgb(220, 222, 232);
        width: 100vw;
    }

    .buttons button{
        border: none;
    }

    .navbar__toggle{
        margin-right: 30px;
    }

    .mob_menu.active{
        display: block;
    }

   .banner_quote{
       display: none;
   }

   #hamb_menu {
    transform: translate(5%, 20%);
    display: block;
    border: 1px solid white;
    }

    #hamb_menu.is-active .bar:nth-child(2) {
        opacity: 1;
    }

    #hamb_menu .bar {
        width: 25px;
        height: 3px;
        margin: 5px auto;
        transition: all 0.3s ease-in-out;
        background: rgb(243, 243, 243);
    }

    #hamb_menu.is-active .bar:nth-child(2) {
        opacity: 0;
      }
    
    #hamb_menu.is-active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }
    
    #hamb_menu.is-active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }

   .about_wrapper{
       width: 80vw;
       margin: 0;
   }

   .about_wrapper p{
       margin: 0;
       
   }

   .skills{
       /* flex-wrap: wrap; */
       display: grid;
       grid-template-columns: 20vw 20vw 20vw 20vw;
       height: auto;
       gap: 5px;
    }

    .projects{
        height: auto;
    }

    .logos{
        width: auto;
    }

    .logos img{
        // width: 50%;
        height: auto;
    }

    li p{
        display: none;
    }

    .footer_link--items{
        font-size: 0.7em;
        width: auto;
    }

    .mobile_proj_links{
        display: inline-block;
    }

    .card-content{
        display: none;
    }

   .content{
       display: flex;
       flex-direction: column;
       align-items: center;
       margin: 0;
       height: auto;
   }

   .card{
    transform: scale(0.6);
    height: 60px;
    }

    .card-content{
        position: absolute;
        top: 0;
        padding: 0;
        text-align: center;
    }

    .card-content h2{
        text-align: center;
        width: max-content;
    }
}

@media (max-width: 530px){
    .banner{
        height: 460px;
    }

    #aboutme{
        margin: 0;
    }

    #hamb_menu{
        display: inline;
    }

    .bullets{
        flex-direction: column;
        align-items: center;
    }

    .bullets_wrapper{
        padding: 0;
        width: 70vw;
    }

    .bullets_wrapper2{
        padding: 0;
        width: 70vw;
    }
}