header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 15px;

    .header-logo{
        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 40px;
        color: white;
        z-index: 1;


        width: auto;

        img{
            margin-right: 12px;
            width: 100%;
            height: auto;
        }
    }
    
    .header_container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        width: auto;
        padding: 0 1vw 0 5vw;
    }
}



