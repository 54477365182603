@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

.language-component {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;

  background: rgba(41, 40, 40, 0.9);

  border-bottom-left-radius: 10px;
  border-top-right-radius: 18px;

  height: 80px;

  position: relative;

  transition: all ease-in-out 200ms;

  &:hover {
    background: rgba(101, 98, 98, 0.5);
  }

  h3 {
    width: 100%;
  }
  p {
    font-size: 0.9em;
  }

  .logo {
    position: absolute;
    top: -60px;
    left: 30px;
  }
}

.language-wrapper {
  position: relative;

  &:hover .language-description-wrapper {
    display: block;
  }

  .language-description-wrapper {
    display: none;

    font-family: monospace;
    background-color: rgb(17, 17, 17);
    color: #00ff00;
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    position: relative;
    border: 1px solid #00ff00;
    border-radius: 4px;

    padding: 10px 20px;
    position: absolute;
    z-index: 999;

    &::before {
      content: ">";
      position: absolute;
      left: 10px;
      top: 10px;
      color: white;
      z-index: 2;
    }

    .language-description {
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      animation: typing 0.3s steps(40, end), blink-caret 0.75s step-end infinite;
      overflow-y: scroll;
      padding: 5px;
      font-family: 'VT323', monospace;
      font-size: 1.2em;

      span {
        &:nth-child(2n) {
          color: #00ff00;
        }
        &:nth-child(2n + 1) {
          color: #00bfff;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .language-component {
    width: 75%;
    font-size: .9em;
  }
}

/* Typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Cursor animations */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #00ff00;
  }
}
