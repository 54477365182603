 /* Import Font */

 @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;1,200;1,300&display=swap');

 /* Variables */
 
//  :root {
//      --gray_font: rgb(237, 236, 236);
//      --green_bg: rgba(135, 169, 138, .8);
//  }
 

 .music-popup{
    width: 220px;
    top: 80px;
    right: 60px;
    position: absolute;
    background-color: rgba(135, 185, 255, 0.526);
    border-radius: 20px;
    padding: 10px 20px;

 }

.music-wrapper{
            background-image: url(../styles/imgs/evie-s-Sw7f58YJbc0-unsplash.jpg);
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            background-attachment: fixed;
            background-repeat: no-repeat;
        
        .header_container {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            border-bottom: 1px solid black;
            height: 120px;
            background-color: var(--green_bg);
            background: #3E5151;
            /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #DECBA4, #3E5151);
            /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #DECBA4, #6e7878);
            /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        }

        .header_container h1 {
            color: var(--gray_font);
        }

        .header_container ul {
            display: flex;
            flex-direction: row;
            list-style-type: none;
        }

        .menu_item {
            margin: 0 10px;
        }

        .menu_item a {
            text-decoration: none;
            color: var(--gray_font);
        }


        /* MAIN */

        .first_row {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
        }

        .first_row_half {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .frhl {
            border-right: 1px solid black;
            background-color: rgba(244, 244, 244, .7);
        }

        .titles {
            text-align: left;
        }

        .first_row_half h2,
        p {
            margin: 20px 0;
        }

        .frhr {
            position: relative;
        }

        .profile_img {
            width: 100%;
            height: auto;
            transition: all ease-in-out 300ms;
        }

        .profile_img:hover {
            opacity: 0.3;
        }

        .hidden_description {
            visibility: hidden;
            position: absolute;
        }

        .visible {
            visibility: inherit;
        }

        .hidden_description ul {
            font-size: 1.2em;
        }


        /* ADS - Video player */

        .second_row {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 50px 0;
            background-color: rgba(0, 0, 0, 0.9);
            color: white;
        }

        .video_player {
            /* border: 1px solid black; */
            width: max-content;
            height: max-content;
        }


        /* Third Row */

        .third_row {
            padding: 30px 0;
            display: flex;
            justify-content: center;
            background-color: var(--green_bg);
        }

        .about_container {
            width: 60%;
            padding: 40px 0;
        }

        .about_container h3 {
            text-align: center;
            margin-bottom: 50px;
        }

        .about_subcontainer {
            display: flex;
        }

        .about_subcontainer p {
            margin: 0 40px;
            text-align: justify;
        }

        .img2 {
            width: 40%;
            height: 100%;
            border-radius: 8%;
        }

        .about_p {
            line-height: 1.5em;
            width: 45%;
            font-weight: 600;
        }

        .fourth_row {
            padding: 90px 0;
            text-align: center;
        }

        .error404_container {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 150px;
        }

        .e404 {
            width: max-content;
            background-color: rgba(131, 179, 174, 0.817);
            padding: 30px;
            border-radius: 20px;
        }


        /* FOOTER */

        .footer_container {
            display: flex;
            justify-content: center;
            bottom: 0;
            background-color: rgb(208, 208, 208);
            padding: 30px 0;
        }

        .footer_ul {
            list-style-type: none;
            color: white;
        }

        .footer_ul a {
            text-decoration: none;
            color: black;
        }
}