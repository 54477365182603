/* About */

.about-section {
    display: flex;
    align-items: center;
    padding: 60px 7vw;
    height: auto;
    background: linear-gradient(to top, rgb(12, 7, 56) 0%, rgb(8, 24, 56) 18%, rgba(12,17,27,1) 40%);

    position: relative;

    @media (max-width:900px){
        flex-direction:column-reverse;
        
    }

    .gray-element1{
        position: absolute;
        width: 163.6px;
        height: 419px;
        left: 0;
        top: 160px;

        background: rgba(255, 255, 255, 0.03);
        border-radius: 40px;
        transform: rotate(45deg);

        z-index: 0;
    }
    .gray-element2{
        position: absolute;
        width: 163.6px;
        height: 419px;
        right: 0;
        top: 150px;
        
        background: rgba(255, 255, 255, 0.03);
        border-radius: 40px;
        transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
    }

    .about_wrapper {
        width: 65%;
        text-align: center;
        justify-content: center;
        align-content: center;
        font-size: 1.1em;
        margin-bottom: 50px;

        h1 {
            margin-right: 230px;
            position: relative;
        }
        
        h1::before, h1::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 70px;
        }
        
        p{
            margin: 20px 70px;
            justify-content: center;
            text-align: justify;

            @media (max-width:900px){
                margin: 0;
            }
        }

            @media (max-width:900px){
                width: 80%;
        }

       
    }
    
    
    
    
    .pic_wrapper {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        z-index: 3;
        position: relative;
    
        #mypic {
            width: 100%;
            border-radius: 2%;
            box-shadow: 1px 5px 8px rgb(34, 34, 34);
        }
        
        .square-behind-pic{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 10px;
            top: 10px;
    
            background: #86BBD8;
            box-shadow: 0px 20px 40px rgba(255, 255, 255, 0.06);
            border-radius: 5px;
    
            z-index: -1;
        }
    }
}

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
