

    .courses{
                width: 80%;
                margin: 0;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
                grid-template-rows: repeat(2, 1fr);
                grid-gap: 2vw;

                z-index: 1;

                .course_wrapper{
                    margin-bottom: 60px;

                    padding: 30px 20px 10px 20px;

                    // border: 1px solid white;
                    background: rgba(41, 40, 40, 0.5);
                    border-bottom-left-radius: 10px;
                    border-top-right-radius: 18px;

                    min-height: 90px;

                    position: relative;

                    text-align: center;

                    span{
                        font-size: 0.9em;
                        color: orange;
                    }

                    .logo, svg{
                        font-size: 3em;
                        position: absolute;
                        top: -20px;
                        left: -10px;
                        color: rgb(0, 162, 255);
                    }
                    
                }
            }

    .courses_deco1{
        position: absolute;
        width: auto;
        // height: 232px;
        left: -30px;
        top: 300px;
        z-index: 0;
    }
    .courses_deco2{
        position: absolute;
        width: auto;
        // height: 232px;
        right: 0;
        top: 100px;
        z-index: 0;
    }
    